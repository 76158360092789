.header-app{
    background-color: #424975;
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: center;
    align-items: center;
}
.header-app h1{
    margin: 0;
}