.grid-card{
    display:grid;
    grid-template-columns: repeat(3,1fr);
}
.card{
    border: 1px solid #424975;
    box-shadow: 1px 8px 6px 3px #424975;
    margin: 1rem;
    width: 20rem;
    height: 90%;
    background-color:#A5B1E1;
}
.card img{
    max-width:250px;
    border: 1px solid #DDD6F8;
    box-shadow: 0px 3px 6px 3px #ddd6f8;
    
}
.card h5{
    font-size: x-large;
}
.card p{
    font-size: large;
}